import { FC, useMemo } from 'react';
import EmptyTableDataSvg from '../../assets/images/empty-table-data.svg';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../contexts/table-view/TableViewFilterContext';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { mainViewId } from '../../models/TableView';

const EmptyTableData: FC = () => {
  const { t } = useTranslation(['table-view']);
  const { selectedTableView, selectedTemplateFormId, onResetFilters } = useTableView();
  const { searchTerm, setSearchTerm } = useTableViewFilters();

  const selectedConfig = useMemo(() => {
    if (!selectedTemplateFormId || !selectedTableView?.columnConfigurations) return null;
    const columnConfigKey =
      selectedTableView?.id === mainViewId && Object.keys(selectedTableView?.columnConfigurations).length === 1 ? mainViewId : selectedTemplateFormId;
    return selectedTableView.columnConfigurations[columnConfigKey];
  }, [selectedTableView.columnConfigurations, selectedTableView?.id, selectedTemplateFormId]);

  const hasFiltersApplied = useMemo(() => {
    if (!selectedConfig) return false;
    return (selectedConfig.columns ?? []).filter((col) => col.filter).length > 0;
  }, [selectedConfig]);

  return (
    <div className="flex h-full flex-col items-center gap-6 text-center">
      <img src={EmptyTableDataSvg} alt={t('empty-table-data.title')} className="max-w-[145px]" />
      <div>
        <Heading size={HeadingSize.H3} textColor="text-black" className="my-2">
          {!!searchTerm || hasFiltersApplied ? t('empty-table-data.title') : t('empty-table-no-data.title')}
        </Heading>
        <div className="text-gray-2">
          {!!searchTerm || hasFiltersApplied ? t('empty-table-data.description') : t('empty-table-no-data.description')}
        </div>
      </div>
      {(!!searchTerm || hasFiltersApplied) && (
        <div className="flex items-center justify-center gap-2">
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onResetFilters}>
            {t('empty-table-data.clear-all-filters')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.PRIMARY} onClick={() => setSearchTerm('')}>
            {t('empty-table-data.clear-search')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyTableData;
