import { graphql } from 'gql.tada';
import { FC, Fragment, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { TableView, allTemplatesId, mainViewConfig } from '../../models/TableView';
import { Translations } from '../../models/Translation';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import LanguageUtils from '../../utils/LanguageUtils';
import { useTranslation } from 'react-i18next';
import { mainViewId } from '../../models/TableView';
import { useQuery } from '@apollo/client';
import TableViewDataTable from './TableViewDataTable';
import { SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import ObjectUtils from '../../utils/ObjectUtils';

const ModuleTemplatesQuery = graphql(`
  query ModuleTemplates($cmId: UUID!, $tmSectionId: UUID) {
    templateFormsByClientModule(first: 100, clientModuleId: $cmId, templateModuleSectionId: $tmSectionId) {
      nodes {
        id
        translations
        placeholders {
          actionIdContainingAssociation
          referencedActionId
          targetId
          target
          translations
          friendlyName
          dataFormat
          placeholder
        }
      }
    }
  }
`);

type Props = {
  clientModuleId: string;
  view: TableView;
  setScrollContainerRef: (ref: RefObject<HTMLDivElement>) => void;
  setColumnWidths: (widths: number[]) => void;
};

const TableViewData: FC<Props> = (props) => {
  const { clientModuleId, view, setColumnWidths, setScrollContainerRef } = props;
  const { templateModuleSectionId } = view;
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const { exportProvider, setSelectedTableView } = useTableView();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation(['table-view', 'assets', 'common', 'module']);

  const { loading: fetchingTemplateforms, data: templateFormData } = useQuery(ModuleTemplatesQuery, {
    variables: {
      cmId: clientModuleId,
      tmSectionId: templateModuleSectionId || undefined, // empty string -> undefined
    },
  });

  const viewTemplates = useMemo(() => {
    if (fetchingTemplateforms) return [];
    const data = templateFormData?.templateFormsByClientModule?.nodes ?? [];
    const allTemplates = view.id === mainViewId ? [{ id: allTemplatesId, translations: {} }] : [];
    if (!templateModuleSectionId || !view.columnConfigurations || view.id === mainViewId || !data) return [...allTemplates, ...data];

    // Looping throug view columns to keep that order
    return [
      ...allTemplates,
      ...Object.entries(view.columnConfigurations)
        .filter(([_, config]) => config.enabled)
        .sort((a, b) => (a[1].sortIndex ?? 0) - (b[1].sortIndex ?? 0))
        .map(([tfId]) => data?.find((x) => x?.id === tfId))
        .filter(Boolean),
    ];
  }, [fetchingTemplateforms, templateFormData?.templateFormsByClientModule?.nodes, templateModuleSectionId, view.columnConfigurations, view.id]);

  const selectedTemplateForm = useMemo(() => {
    return viewTemplates?.find((x) => x?.id === selectedTemplateId);
  }, [selectedTemplateId, viewTemplates]);

  const onTemplateChange = useCallback(
    (id: string) => {
      if (!id) return;
      setSelectedTemplateId(id);
      // reset the main view config if the main view is selected
      if (view.id === mainViewId && setSelectedTableView) {
        setSelectedTableView((prev) => {
          if (!prev || JSON.stringify(prev.columnConfigurations) === JSON.stringify(mainViewConfig)) return prev;
          return {
            ...prev,
            columnConfigurations: { ...ObjectUtils.DeepClone(mainViewConfig) },
          };
        });
      }
    },
    [setSelectedTableView, view.id],
  );

  useEffect(() => {
    if (!viewTemplates.length) return;
    const enabledTemplateIds = viewTemplates?.map((template) => template?.id) || [];
    const isTemplateInEnabledList = enabledTemplateIds.includes(selectedTemplateId);
    if (!isTemplateInEnabledList) {
      onTemplateChange(viewTemplates[0]?.id as string);
    }
  }, [onTemplateChange, selectedTemplateId, templateModuleSectionId, viewTemplates]);

  useEffect(() => {
    if (!viewTemplates.length) return;
    if (view.id === mainViewId) {
      onTemplateChange(allTemplatesId);
    }
  }, [onTemplateChange, view.id, viewTemplates.length]);

  return (
    <div className="h-full w-full">
      <TabStrip
        key={templateModuleSectionId + view.id + viewTemplates.length}
        position="bottom"
        overflowTabs
        centered={false}
        borderless
        fillSelected
        onChange={(id) => onTemplateChange(id)}
        headerClassName="fixed bottom-0 bg-white pb-1 z-10"
        contentClassName="h-full overflow-y-auto"
      >
        {viewTemplates?.map((tf) => {
          const selectedTemplateName =
            tf?.id === allTemplatesId ? t('tabs.all') : LanguageUtils.getTranslation('title', tf?.translations as Translations, lang);
          return (
            <Fragment key={`view-${tf?.id}`}>
              <TabStrip.TabHeader key={tf!.id as string} id={tf!.id as string} text={selectedTemplateName} />

              <TabStrip.TabContent key={tf!.id as string} forId={tf!.id as string}>
                {tf?.id === selectedTemplateForm?.id ? (
                  <SelectItemContextProvider ref={exportProvider}>
                    <TableViewDataTable
                      clientModuleId={clientModuleId}
                      setColumnWidths={setColumnWidths}
                      setScrollContainerRef={setScrollContainerRef}
                      view={view}
                      viewTemplatesLength={viewTemplates?.length}
                      selectedTemplateForm={selectedTemplateForm}
                      selectedTemplateName={selectedTemplateName}
                    />
                  </SelectItemContextProvider>
                ) : null}
              </TabStrip.TabContent>
            </Fragment>
          );
        })}
      </TabStrip>
    </div>
  );
};

export default TableViewData;
