/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, RefObject, createElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnConfig,
  ColumnType,
  SortDirection,
  TableView,
  TableViewMetaDataKey,
  allTemplatesId,
  freezeLineId,
  graphQLMetadataFields,
  mainViewId,
  tableViewMetadata,
} from '../../models/TableView';
import EmptyTableData from './EmptyTableData';
import { useQuery } from '@apollo/client';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender, Cell, Row, SortingState, getSortedRowModel, OnChangeFn } from '@tanstack/react-table';
import { graphql } from 'gql.tada';
import { useTranslation } from 'react-i18next';
import { FormActionContext } from '../../contexts/FormActionContext';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { useTableViewFilters, ActionColumn } from '../../contexts/table-view/TableViewFilterContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import { ClientFormUser } from '../../models/ClientFormUser';
import { Roles } from '../../models/Role';
import { buildFilters, buildOrdering } from '../../models/TableViewFilters';
import ClientFormService from '../../services/ClientFormService';
import FormUtils from '../../utils/FormUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { toRecord, recordMap } from '../../utils/ListUtils';
import { goToClientForm } from '../../utils/NavigationUtils';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';
import { GraphQLPlaceholderTargetToEnum, FormBuilderPlaceholder } from '../form-builder/FormBuilderTypes';
import ActionTypes, { ActionTypeNames } from '../form/ActionTypes';
import { DataRenderers, DataRendersKeys } from './data-renderers/DataRenderers';
import DefaultTextRenderer from './data-renderers/DefaultTextRenderer';
import { GraphQLRoleToPlatformRole } from './data-renderers/MembersRenderer';
import { ModalContext } from '../../contexts/ModalContext';
import FormInfoModal from '../form/FormInfoModal';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import Tooltip from '../shared/Tooltip';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import Checkbox from '../shared/form-control/Checkbox';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import useDebounce from '../../hooks/useDebounce';
import { ActionPlaceholderData } from '../../models/Form';
import ObjectUtils from '../../utils/ObjectUtils';
import { useImportFilter } from '../../hooks/useImportFilter';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';
import DataTableLayout from '../shared/data-grid/DataTableLayout';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import {
  SELECT_COLUMN_ID,
  ROW_NUMBER_COLUMN_ID,
  SELECT_COLUMN_WIDTH,
  ROW_NUMBER_COLUMN_WIDTH,
  PerPageCount,
  SIDEBAR_WIDTH,
  EMPTY_ARRAY,
} from './TableViewConstants';

type TableViewDataTableProps = {
  selectedTemplateForm: any;
  clientModuleId: string;
  view: TableView;
  setScrollContainerRef: (ref: RefObject<HTMLDivElement>) => void;
  setColumnWidths: (widths: number[]) => void;
  viewTemplatesLength: number;
  selectedTemplateName: string;
};

const TemplateFormQuery = graphql(`
  query TemplateForm($tfId: UUID!) {
    templateFormById(id: $tfId) {
      id
      translations
      sections {
        id
        actions {
          id
          type
          data
          translations
        }
      }
    }
  }
`);

const SortableMetadataColumns: TableViewMetaDataKey[] = ['subTitle', 'dueDate', 'lastModified', 'effectiveDate', 'createdOn', 'progress'];
const FilterColumnBlacklist = ['version', 'periodicNextReview', 'displayTitle', 'subTitle', SELECT_COLUMN_ID, ROW_NUMBER_COLUMN_ID];
const UserMetadatacColumns: TableViewMetaDataKey[] = ['members', 'owner', 'approver', 'reviewer', 'viewer', 'editor'];
const SpecialColumnDefaultWidths: Record<string, number> = {
  subTitle: window.innerWidth / 4,
  progress: 250,
  [SELECT_COLUMN_ID]: SELECT_COLUMN_WIDTH,
  [ROW_NUMBER_COLUMN_ID]: ROW_NUMBER_COLUMN_WIDTH,
};

const TableViewDataTable: FC<TableViewDataTableProps> = (props) => {
  const { view, setColumnWidths, setScrollContainerRef, selectedTemplateForm, viewTemplatesLength, selectedTemplateName, clientModuleId } = props;
  const { setResultCounts, setSelectedTemplateFormId, setSelectedTableView, clientModule, clientModuleSectionId, setRenderedGQL } = useTableView();
  const { templateModuleSectionId } = view;
  const currentClient = useCurrentClient((x) => x.value);
  const currentUser = useCurrentUser((x) => x.value);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const scrollContainerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [showDetailsModal, setShowDetailsModal] = useState<[boolean, { id: string } | null, ClientFormUser[] | null]>([false, null, null]);
  const [showArchiveConfim, setShowArchiveConfim] = useState<[boolean, { id: string; isShared: boolean }]>([false, { id: '', isShared: false }]);
  const { searchTerm, setActionsTypes } = useTableViewFilters();
  const { importId } = useImportFilter();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const hasPermission = usePermissions();
  const navigate = useNavigate();

  const { selection, mutateSelection, SelectAllButton } = useItemSelection();
  const [rowSelection, setRowSelection] = useState({});
  const {
    t,
    i18n: { language: lang },
  } = useTranslation(['table-view', 'assets', 'common', 'module', 'data-table']);

  const { featureFlags } = useFeatureFlags();

  const showRowNumbers = useMemo(
    () => view.columnConfigurations?.[selectedTemplateForm?.id as string]?.enableIndexNumbering ?? false,
    [selectedTemplateForm?.id, view.columnConfigurations],
  );

  useEffect(() => {
    const columnConfigKey = view.id === mainViewId ? mainViewId : (selectedTemplateForm!.id as string);
    const selectedColumnConfiguration = view.columnConfigurations?.[columnConfigKey] ?? { columns: [] };

    // Check if the Title column (subTitle) is missing or not in the first position
    // This should not happen but if it does, move the Title column to the first position
    const titleColumnIndex = selectedColumnConfiguration.columns.findIndex((col) => col.value === tableViewMetadata.subTitle);
    if (titleColumnIndex !== 0) {
      setSelectedTableView &&
        setSelectedTableView((prev) => {
          if (!prev) return prev;

          // Clone existing columns and move Title to the first position
          let newColumns = selectedColumnConfiguration.columns.filter((col) => col.value !== tableViewMetadata.subTitle);
          newColumns = [{ value: tableViewMetadata.subTitle, type: ColumnType.MetaData, freeze: true }, ...newColumns];

          return {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [columnConfigKey]: {
                ...prev.columnConfigurations?.[columnConfigKey],
                columns: newColumns,
                enabled: prev.columnConfigurations?.[columnConfigKey]?.enabled ?? true,
                sortIndex: prev.columnConfigurations?.[columnConfigKey]?.sortIndex ?? 0,
                enableIndexNumbering: prev.columnConfigurations?.[columnConfigKey]?.enableIndexNumbering ?? false,
              },
            },
          };
        });
    }
  }, [selectedTemplateForm, setSelectedTableView, view.columnConfigurations, view.id]);

  //shift+mouse scroll to horizontal scroll the table
  useEffect(() => {
    const scrollContainer = scrollContainerRefs.current[selectedTemplateIndex];
    if (!scrollContainer) return;

    const handleScroll = (event: WheelEvent) => {
      if (event.shiftKey) {
        event.preventDefault();
        scrollContainer.scrollLeft += event.deltaY;
      }
    };

    scrollContainer.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      scrollContainer.removeEventListener('wheel', handleScroll);
    };
  }, [selectedTemplateIndex]);

  useEffect(() => {
    setSelectedTemplateIndex(0);
  }, [templateModuleSectionId, view.id]);

  const enableExport = useMemo(() => hasPermission(Roles.TeamMember) && featureFlags.dataExport, [featureFlags.dataExport, hasPermission]);

  const metadataFields = useMemo(() => {
    if (!selectedTemplateForm) return '';

    const columnConfigKey = view.id === mainViewId ? mainViewId : (selectedTemplateForm!.id as string);
    const fieldInfos =
      view.columnConfigurations?.[columnConfigKey]?.columns
        .filter((x) => x.type == ColumnType.MetaData)
        .map((x) => graphQLMetadataFields[x.value as keyof typeof graphQLMetadataFields]) ?? [];

    const selectedFields = fieldInfos.reduce((acc, x) => ({ ...acc, [x.field]: x.subFields }), {} as Record<string, string[] | undefined>);
    const selectedAuxilliaryFields = new Set(fieldInfos.flatMap((x) => x.auxillaryFields));
    for (const field of Object.keys(selectedFields)) {
      selectedAuxilliaryFields.delete(field);
    }

    let result = '';
    for (const field of Object.keys(selectedFields)) {
      result += field + ' ';

      const subFields = selectedFields[field];
      if (subFields) {
        result += `{ ${subFields.join(' ')} } `;
      }
    }

    result += ' ' + [...selectedAuxilliaryFields].join(' ');

    return result;
  }, [selectedTemplateForm, view.columnConfigurations, view.id]);

  const clientFormsQueryRaw = useMemo(
    () => `
      query DataGrid($tfId: UUID, $cmId: UUID, $cmsId: UUID, $perPage: Int!, $after: String, $where: ClientFormFilterInput, $attachmentIds: [UUID!], $referenceIds: [UUID!], $periodicReviewStatuses: [PeriodicReviewStatus!], $distributionStatuses: [DistributionStatusFilter!], $order: [ClientFormSortInput!], $dataJobId: UUID) {
        clientForms(first: $perPage, after: $after, templateFormId: $tfId, clientModuleId: $cmId, clientModuleSectionId: $cmsId, order: $order, where: $where, attachmentIds: $attachmentIds, referenceIds: $referenceIds, periodicReviewStatuses: $periodicReviewStatuses, distributionStatuses: $distributionStatuses, dataJobId: $dataJobId) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          nodes {
            id
            displayTitle
            clientId
            templateFormId
            templateFormVersion
            archivedUtc
            clientModuleId
            isShared
            templateForm {
              translations
            }
            users {
              userId
              role
              formSectionId
              acceptedStatus
              sortOrder
              pbkUser {
                id
                firstName
                lastName
                userImageId
              }
            }
            
            ${metadataFields}

            answers {
              ... on FormAnswer {
                id
                actionId
                data
                actionType
              }
            }

            references {
              displayTitle
              id
              __typename
            }

            placeholders {
              key
              value {
                actionIds
                actionType
                answers
                clientFormIds
                dataFormat
                friendlyName
                translations
              }
            }
          }
        }
      }
    `,
    [metadataFields],
  );

  const clientFormsQuery = useMemo(() => {
    return graphql(clientFormsQueryRaw);
  }, [clientFormsQueryRaw]);

  const { data: templateForm } = useQuery(TemplateFormQuery, {
    variables: {
      tfId: selectedTemplateForm?.id,
    },
    skip: !selectedTemplateForm || selectedTemplateForm.id === allTemplatesId,
  });

  const [actions, sections] = useMemo<[Record<string, any>, Record<string, any>]>(() => {
    if (!templateForm) return [{}, {}];

    const sections = templateForm.templateFormById?.sections ?? [];
    const actions = sections
      .map((s) => s?.actions?.map((a) => ({ ...a, section: s })) ?? [])
      .flat()
      .filter(Boolean);
    const result = toRecord(actions, 'id');

    return [result, recordMap(result, (_, val: any) => val.section)];
  }, [templateForm]);

  const builtFilter = useMemo(() => {
    return buildFilters(view.columnConfigurations?.[selectedTemplateForm?.id as string]?.columns || [], actions, debouncedSearchTerm);
  }, [actions, selectedTemplateForm?.id, view.columnConfigurations, debouncedSearchTerm]);

  const orderBy = useMemo(() => {
    return buildOrdering(view.columnConfigurations?.[selectedTemplateForm?.id as string]?.columns || []);
  }, [selectedTemplateForm?.id, view.columnConfigurations]);

  const clientFormDataVariables = useMemo(
    () => ({
      tfId: selectedTemplateForm?.id === allTemplatesId ? undefined : selectedTemplateForm?.id,
      cmId: clientModuleId,
      cmsId: clientModuleSectionId || undefined,
      perPage: PerPageCount,
      where: builtFilter.where,
      attachmentIds: builtFilter.attachmentIds,
      referenceIds: builtFilter.referenceIds,
      periodicReviewStatuses: builtFilter.periodicReviewStatuses,
      distributionStatuses: builtFilter.distributionStatuses,
      order: orderBy,
      dataJobId: importId,
    }),
    [
      builtFilter.attachmentIds,
      builtFilter.distributionStatuses,
      builtFilter.periodicReviewStatuses,
      builtFilter.referenceIds,
      builtFilter.where,
      clientModuleId,
      clientModuleSectionId,
      importId,
      orderBy,
      selectedTemplateForm?.id,
    ],
  );

  const {
    loading: fetchingClientforms,
    data: clientFormData,
    refetch: refetchClientForms,
    fetchMore: fetchMoreClientForms,
  } = useQuery(clientFormsQuery, {
    variables: clientFormDataVariables,
    skip: !selectedTemplateForm,
  });

  const fetchNextPage = useCallback(() => {
    if (!clientFormData?.clientForms?.pageInfo.hasNextPage) return;
    fetchMoreClientForms({
      variables: {
        after: clientFormData.clientForms.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          clientForms: {
            ...fetchMoreResult.clientForms,
            nodes: [...prev.clientForms.nodes, ...fetchMoreResult.clientForms.nodes],
          },
        };
      },
    });
  }, [clientFormData?.clientForms?.pageInfo.endCursor, clientFormData?.clientForms?.pageInfo.hasNextPage, fetchMoreClientForms]);

  const [infiniteLoadingRef] = useInfiniteScroll(fetchNextPage, fetchingClientforms);

  useEffect(() => {
    setRenderedGQL({ query: clientFormsQueryRaw, variables: clientFormDataVariables });
  }, [clientFormDataVariables, clientFormsQueryRaw, setRenderedGQL]);

  const formPlaceholders = useMemo(() => {
    const result: Record<string, Record<string, ActionPlaceholderData>> = {};
    if (!clientFormData?.clientForms) return result;

    for (const cf of clientFormData.clientForms.nodes) {
      result[cf.id] = cf.placeholders.reduce(
        (acc: any, x: any) => {
          acc[x.key] = x.value;
          return acc;
        },
        {} as Record<string, ActionPlaceholderData>,
      );
    }

    return result;
  }, [clientFormData]);

  const columns = useMemo<ColumnDef<any>[]>(() => {
    const selectRowColumn: ColumnDef<any> = {
      id: SELECT_COLUMN_ID,
      size: SELECT_COLUMN_WIDTH,
      enableResizing: false,
      enableSorting: false,
      meta: {
        frozen: true,
      },
      header: ({ table }) => SelectAllButton({ visibleItems: table.getRowModel().rows.map((x) => x.original) }),
      cell: ({ row }: any) => (
        <div>
          <Checkbox
            {...{
              containerClassName: '!my-0',
              value: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onChange: (checked) => {
                mutateSelection.selectIndividual(row.original, checked);
              },
            }}
          />
        </div>
      ),
    };
    const rowNumberColumn: ColumnDef<any> = {
      id: ROW_NUMBER_COLUMN_ID,
      header: '#',
      enableSorting: false,
      enableResizing: false,
      size: ROW_NUMBER_COLUMN_WIDTH,
      cell: ({ row }) => <span>{row.index + 1}</span>, // Display row index + 1 for numbering
      meta: {
        frozen: true, // Optionally freeze the column if required
      },
    };

    const cols: ColumnDef<any>[] = enableExport ? [selectRowColumn] : [];
    if (showRowNumbers) {
      cols.push(rowNumberColumn);
    }

    if (!selectedTemplateForm) return cols;
    const columnConfigKey = view.id === mainViewId ? mainViewId : (selectedTemplateForm!.id as string);
    for (const colConfig of view.columnConfigurations?.[columnConfigKey]?.columns ?? []) {
      if (colConfig.type === ColumnType.MetaData) {
        const queryFieldsInfo = graphQLMetadataFields[colConfig.value as keyof typeof graphQLMetadataFields];
        // Always show template column in the main view for ALL tabs
        if (colConfig.value === tableViewMetadata.template && selectedTemplateForm?.id !== allTemplatesId && view.id === mainViewId) {
          continue;
        }
        cols.push({
          header: t(`meta.${colConfig.value}` as any),
          accessorKey: queryFieldsInfo.field,
          id: colConfig.value,
          enableSorting: SortableMetadataColumns.includes(colConfig.value as TableViewMetaDataKey),
          minSize: 100,
          meta: {
            type: ColumnType.MetaData,
            frozen: colConfig.freeze,
            filter: colConfig.filter,
          },
        });
      } else if (colConfig.type === ColumnType.Action && colConfig.value !== freezeLineId) {
        const action = actions[colConfig.value];
        if (!action) continue;

        const translatedData = LanguageUtils.getActionDataTranslation(action, lang);
        const placeholders = FormUtils.formPlaceholdersToActionPlaceholderData(
          selectedTemplateForm?.placeholders?.map((p: any) =>
            p ? { ...p, target: GraphQLPlaceholderTargetToEnum[p.target] } : p,
          ) as FormBuilderPlaceholder[],
        );

        const question = interpolateActionData(
          translatedData?.previewQuestion || translatedData?.question || translatedData?.title || '',
          placeholders,
        );

        cols.push({
          header: question as any,
          accessorFn: (row) => {
            return row?.answers?.find((x: any) => x.actionId === action.id)?.data;
          },
          id: `${action.id}`,
          enableSorting: false,
          minSize: 100,
          meta: {
            type: ColumnType.Action,
            frozen: colConfig.freeze,
            filter: colConfig.filter,
          },
        });
      }
    }
    return cols;
  }, [SelectAllButton, actions, enableExport, lang, mutateSelection, selectedTemplateForm, showRowNumbers, t, view.columnConfigurations, view.id]);

  useEffect(() => {
    const actionTypes: Record<string, ActionColumn> = {};

    columns.forEach((col) => {
      if ((col.meta as ColumnConfig).type === ColumnType.Action && col.id) {
        const action = actions[col.id];
        if (action?.id) {
          actionTypes[action.id] = { actionType: action.type, title: col.header as any };
        }
      }
    });

    setActionsTypes(actionTypes);
  }, [columns, actions, setActionsTypes]);

  const initialColumnWidths = useMemo(() => {
    const specialColumnsWidth = columns.reduce((acc, col) => {
      if (col?.id && col.id in SpecialColumnDefaultWidths) {
        return acc + SpecialColumnDefaultWidths[col.id];
      }

      return acc;
    }, 0);

    const availableWidth = window.innerWidth - SIDEBAR_WIDTH - 20 * 2 - specialColumnsWidth;

    const otherColumnCount = columns.reduce((acc, col) => {
      return col?.id && !(col.id in SpecialColumnDefaultWidths) ? acc + 1 : acc;
    }, 0);

    const otherColumnWidth = Math.max(150, availableWidth / otherColumnCount);

    const columnWidths = columns.map((x) => ({
      id: x.id || '',
      width: x.id && x.id in SpecialColumnDefaultWidths ? SpecialColumnDefaultWidths[x.id] : otherColumnWidth,
    }));

    return recordMap(toRecord(columnWidths, 'id'), (_, val) => val.width);
  }, [columns]);

  const frozenColumns = useMemo(() => {
    return columns.filter((x) => (x.meta as any)?.frozen).map((x) => x.id!);
  }, [columns]);

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    refetchClientForms();
  }, [refetchClientForms, currentClient?.id, selectedTemplateForm?.id, view.id, sorting, debouncedSearchTerm, columns]);

  useEffect(() => {
    // Extract initial sorting from view configuration
    const initializeSorting = () => {
      const columnConfigKey = view.id === mainViewId ? mainViewId : (selectedTemplateForm!.id as string);
      const columnsConfig = view.columnConfigurations?.[columnConfigKey]?.columns ?? [];

      const initialSorting: SortingState = columnsConfig
        .filter((col) => col.sortDirection !== undefined && col.sortDirection !== null)
        .map((col) => ({
          id: col.value,
          desc: col.sortDirection === SortDirection.Descending,
        }));

      if (initialSorting.length === 0) {
        return [{ id: tableViewMetadata.subTitle, desc: false }];
      }

      return initialSorting;
    };

    const initialSortingState = initializeSorting();
    setSorting(initialSortingState);
  }, [selectedTemplateForm, view.id, setSorting, view.columnConfigurations]);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (newSorting) => {
      setSorting((prevSorting) => {
        const updatedSorting = typeof newSorting === 'function' ? newSorting(prevSorting) : newSorting;

        const fallbackSorting: SortingState = updatedSorting.length > 0 ? updatedSorting : [{ id: tableViewMetadata.subTitle, desc: false }];

        // Update view configuration with the new sorting state
        setSelectedTableView &&
          setSelectedTableView((prev) => {
            if (!prev) return prev;

            const columnConfigKey = selectedTemplateForm!.id as string;

            let newColumns = prev.columnConfigurations?.[columnConfigKey]?.columns ?? [];

            // If the main view is selected, clone the columns to the selected templateFormId
            if (view.id === mainViewId) {
              const mainViewColumns = prev.columnConfigurations?.[mainViewId]?.columns ?? [];
              if (!prev.columnConfigurations || !prev.columnConfigurations[selectedTemplateForm!.id]) {
                const clonedColumns = mainViewColumns.map((col) => ({ ...col }));
                const newColumnConfiguration = {
                  ...prev.columnConfigurations?.[mainViewId],
                  id: undefined,
                  enabled: true,
                  columns: clonedColumns,
                  sortIndex: prev.columnConfigurations?.[mainViewId]?.sortIndex ?? 0,
                  enableIndexNumbering: prev.columnConfigurations?.[mainViewId]?.enableIndexNumbering ?? false,
                };

                prev = {
                  ...prev,
                  columnConfigurations: {
                    ...prev.columnConfigurations,
                    [selectedTemplateForm!.id]: newColumnConfiguration,
                  },
                };
              }
              newColumns = prev.columnConfigurations?.[selectedTemplateForm!.id]?.columns ?? [];

              // Update sorting in the main view columns as well
              const prevMainColumnsSorting = mainViewColumns
                .filter((c) => c.sortDirection !== undefined && c.sortDirection !== null)
                .map((col) => ({ id: col.value, desc: col.sortDirection === SortDirection.Descending }));
              const updatedMainViewColumns = mainViewColumns.map((col) => {
                const sortIndex = fallbackSorting.findIndex((s) => s.id === col.value);
                const sort = fallbackSorting[sortIndex];
                col.sortDirection = sort ? (sort.desc ? SortDirection.Descending : SortDirection.Ascending) : undefined;
                col.sortDirectionOrder = sort
                  ? prevMainColumnsSorting.findIndex((s) => s.id === col.value && s.desc === (col.sortDirection === SortDirection.Descending)) > -1
                    ? sortIndex + 1
                    : 0
                  : 9999;
                return col;
              });
              // Apply updated sorting to both main view and cloned columns
              return {
                ...prev,
                columnConfigurations: {
                  ...prev.columnConfigurations,
                  [mainViewId]: {
                    ...prev.columnConfigurations?.[mainViewId],
                    columns: updatedMainViewColumns,
                    enabled: true,
                    enableIndexNumbering: false,
                  },
                  [columnConfigKey]: {
                    ...prev.columnConfigurations?.[columnConfigKey],
                    columns: updatedMainViewColumns.map((col) => ({ ...col })),
                    enabled: prev.columnConfigurations?.[columnConfigKey]?.enabled ?? true,
                    sortIndex: prev.columnConfigurations?.[columnConfigKey]?.sortIndex ?? 0,
                    enableIndexNumbering: prev.columnConfigurations?.[columnConfigKey]?.enableIndexNumbering ?? false,
                  },
                },
              };
            }

            // If not in main view, update only the selected template columns
            const prevUpdatedColumnsSorting = newColumns
              .filter((c) => c.sortDirection !== undefined && c.sortDirection !== null)
              .map((col) => ({ id: col.value, desc: col.sortDirection === SortDirection.Descending }));
            const updatedColumns = newColumns.map((col) => {
              const sortIndex = fallbackSorting.findIndex((s) => s.id === col.value);
              const sort = fallbackSorting[sortIndex];
              col.sortDirection = sort ? (sort.desc ? SortDirection.Descending : SortDirection.Ascending) : undefined;
              col.sortDirectionOrder = sort
                ? prevUpdatedColumnsSorting.findIndex((s) => s.id === col.value && s.desc === (col.sortDirection === SortDirection.Descending)) > -1
                  ? sortIndex + 1
                  : 0
                : 9999;
              return col;
            });

            return {
              ...prev,
              columnConfigurations: {
                ...prev.columnConfigurations,
                [columnConfigKey]: {
                  ...prev.columnConfigurations?.[columnConfigKey],
                  columns: updatedColumns,
                  enabled: prev.columnConfigurations?.[columnConfigKey]?.enabled ?? true,
                  sortIndex: prev.columnConfigurations?.[columnConfigKey]?.sortIndex ?? 0,
                  enableIndexNumbering: prev.columnConfigurations?.[columnConfigKey]?.enableIndexNumbering ?? false,
                },
              },
            };
          });

        return fallbackSorting;
      });
    },
    [selectedTemplateForm, setSelectedTableView, view.id],
  );

  const table = useReactTable({
    data: clientFormData?.clientForms?.nodes ?? EMPTY_ARRAY,
    columns: columns.map((col) => ({
      ...col,
      size: initialColumnWidths[col.id as string],
    })),
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: 150,
    },
    enableColumnResizing: true,
    pageCount: Math.floor(clientFormData?.clientForms?.totalCount ?? 0 / PerPageCount),
    state: {
      columnPinning: {
        left: frozenColumns,
      },
      rowSelection,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: true,
    manualSorting: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    isMultiSortEvent: () => true,
    onSortingChange: handleSortingChange,
  });

  // Calculate column widths for snapping
  useEffect(() => {
    const columnWidths = columns.map((col) => table.getColumn(col.id as string)?.getSize() || 150);
    setColumnWidths(columnWidths);
  }, [columns, setColumnWidths, table]);

  const hasData = useMemo(() => {
    return (clientFormData?.clientForms?.totalCount ?? 0) > 0;
  }, [clientFormData?.clientForms?.totalCount]);

  const dataCellContent = useCallback(
    (cell: Cell<any, unknown>) => {
      if (cell.column.id === SELECT_COLUMN_ID || cell.column.id === ROW_NUMBER_COLUMN_ID) {
        return flexRender(cell.column.columnDef.cell, cell.getContext());
      }

      const row = cell.row.original;
      const data = cell.getValue();

      const type = (cell.column.columnDef.meta as any)?.type as ColumnType;
      if (!data) return <>-</>;

      if (type === ColumnType.MetaData) {
        const renderer = DataRenderers[cell.column.id as DataRendersKeys]?.render;
        const value = createElement(renderer ?? DefaultTextRenderer, { data, row });

        if (UserMetadatacColumns.includes(cell.column.id as TableViewMetaDataKey)) {
          return <>{value}</>;
        }

        return (
          <Tooltip text={value} truncatedTextMode>
            {(tooltip) => (
              <div className="select-none truncate" {...tooltip}>
                {value}
              </div>
            )}
          </Tooltip>
        );
      } else {
        const actionId = cell.column.id;
        const action = actions[actionId];
        if (!action) return <>-</>;

        const renderer = ActionTypes[action.type as ActionTypeNames]?.previewAction;

        const translatedData = LanguageUtils.getActionDataTranslation(action as any, lang);

        const placeholders = ObjectUtils.DeepClone(formPlaceholders[row.id]);

        const value = createElement(renderer, {
          id: `${action.id}`,
          data: translatedData,
          response: data,
          answerOnly: true,
          required: false,
          plainText: (action.type as ActionTypeNames) === 'TextInputAction',
          placeholders: placeholders,
        });

        return (
          <div className="max-h-16 overflow-y-auto overflow-x-clip">
            <FormActionContext.Provider
              value={{
                currentSection: sections[actionId],

                currentAction: { ...action, visible: 'true', data: translatedData } as any,
                actionValid: true,
                riskLevel: 0,
                readOnly: true,
                busySavingAction: false,
                onAnswer: () => Promise.resolve(),
                onValid: () => ({}),
                createPlaceholder: () => ({}),
                deletePlaceholder: () => ({}),
              }}
            >
              <Tooltip text={value} truncatedTextMode>
                {(tooltip) => (
                  <div className="select-none truncate" {...tooltip}>
                    {value}
                  </div>
                )}
              </Tooltip>
            </FormActionContext.Provider>
          </div>
        );
      }
    },
    [actions, formPlaceholders, lang, sections],
  );

  useEffect(() => {
    const selectedRef = scrollContainerRefs.current[selectedTemplateIndex];
    if (selectedRef && viewTemplatesLength) {
      setScrollContainerRef({ current: selectedRef });
    }
  }, [selectedTemplateIndex, setScrollContainerRef, view, viewTemplatesLength]);

  const isShared = useCallback(
    (formClientId: string) => {
      return formClientId !== currentClient?.id;
    },
    [currentClient?.id],
  );

  const { triggerPdfDownload, pdfDownloadModal } = useDownloadPdf();

  const contextItems = (clientForm: any) => {
    const isSharedReceiver = clientForm.isShared && clientForm.clientId !== currentClient?.id;
    return [
      {
        title: t('assets:list.context-menu.details'),
        onClick: () =>
          setShowDetailsModal([
            true,
            { id: clientForm.id },

            clientForm.users.map((u: any) => {
              return {
                ...u,
                id: u.userId,
                role: GraphQLRoleToPlatformRole[u.role],
                firstName: u.pbkUser.firstName,
                lastName: u.pbkUser.lastName,
              };
            }),
          ]),
      },
      {
        title: t('assets:list.context-menu.preview'),
        onClick: () =>
          goToClientForm(
            {
              id: clientForm.id,
              clientId: clientForm.clientId,
              status: clientForm.status,
              type: clientForm.type,
              templateId: clientForm.templateFormId,
            },
            currentUser,
            navigate,
            true,
          ),
        hide: isShared(clientForm.clientId),
      },
      {
        title: t('common:list.context-menu.archive'),
        onClick: () => {
          setShowArchiveConfim([true, { id: clientForm.id, isShared: isShared(clientForm.clientId) }]);
        },
        hide: !hasPermission(Roles.TeamMember) || isShared(clientForm.clientId) || clientForm.archivedUtc,
      },
      {
        title: t('module:list.context-menu.restore'),
        onClick: () => onRestore(clientForm.id),
        hide: isSharedReceiver || !clientForm.archivedUtc,
      },
      {
        title: t('assets:list.context-menu.export'),
        onClick: () => {
          triggerPdfDownload(clientForm.id, clientForm.displayTitle);
        },
        hide: hasPermission(Roles.ExternalAuditor) && !hasPermission(Roles.Management),
      },
    ];
  };

  const onArchive = useCallback(() => {
    if (showArchiveConfim[0]) {
      ClientFormService.archiveForm(showArchiveConfim[1].id).then(() => {
        setShowArchiveConfim([false, { id: '', isShared: false }]);
        refetchClientForms();
      });
    }
  }, [refetchClientForms, showArchiveConfim]);

  const onRestore = useCallback(
    (id: string) => {
      ClientFormService.restoreForm(id).then(() => {
        refetchClientForms();
      });
    },
    [refetchClientForms],
  );

  // Set counts when data is fetched or updated
  useEffect(() => {
    if (clientFormData?.clientForms) {
      const count = clientFormData.clientForms.nodes.length;
      const total = Math.max(0, clientFormData.clientForms.totalCount);
      setResultCounts((prev) => ({ ...prev, count, total }));
    }
  }, [clientFormData, setResultCounts]);

  useEffect(() => {
    setSelectedTemplateFormId(selectedTemplateForm?.id as string);
  }, [selectedTemplateForm?.id, setSelectedTemplateFormId]);

  useEffect(() => {
    setRowSelection(
      Object.assign(
        {},
        ...table.getRowModel().rows.map((row: Row<any>, i) => {
          return { [i]: selection.isSelected(row.original) };
        }),
      ),
    );
  }, [selection, table]);

  const setScrollContainerElement = useCallback(
    (el: HTMLDivElement) => {
      scrollContainerRefs.current[selectedTemplateIndex] = el;
    },
    [selectedTemplateIndex],
  );

  return (
    <>
      {clientModule && (
        <DataTableLayout
          table={table}
          hasData={hasData}
          isFetching={fetchingClientforms}
          totalRecords={clientFormData?.clientForms?.totalCount}
          setScrollContainerRef={setScrollContainerElement}
          clientModule={clientModule}
          infiniteLoadingRef={infiniteLoadingRef}
          dataCellContent={dataCellContent}
          selectedTemplateId={selectedTemplateForm?.id}
          filterColumnBlacklist={FilterColumnBlacklist}
          rowLinkRoute={(row) => `/clients/${row.original.clientId}/forms/${row.original.id}`}
          contextItems={(row) => contextItems(row.original)}
        >
          <DataTableLayout.Slot name="Empty">
            <EmptyTableData />
          </DataTableLayout.Slot>
        </DataTableLayout>
      )}
      {showDetailsModal[1] && showDetailsModal[2] && (
        <FormInfoModal
          open={showDetailsModal[0]}
          onClose={() => setShowDetailsModal([false, null, null])}
          form={showDetailsModal[1]}
          clientFormUsers={showDetailsModal[2]}
        />
      )}
      <ModalContext.Provider
        value={{ open: showArchiveConfim[0], onClose: () => setShowArchiveConfim([false, { id: '', isShared: false }]), modalWidth: 'w-2/5' }}
      >
        <ConfirmationModal
          title={t('assets:list.archive-modal.heading')}
          description={showArchiveConfim[1].isShared ? t('assets:list.archive-modal.body-shared') : t('assets:list.archive-modal.body')}
          confirmText={t('assets:list.archive-modal.buttons.archive')}
          cancelText={t('assets:list.archive-modal.buttons.cancel')}
          onConfirm={onArchive}
          onCancel={() => setShowArchiveConfim([false, { id: '', isShared: false }])}
          alt
        />
      </ModalContext.Provider>
      {pdfDownloadModal}
    </>
  );
};

export default TableViewDataTable;
